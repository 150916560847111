@import '@fortawesome/fontawesome-free/css/all.css';
@import 'src/styles/variables';
@import 'bulma/bulma';
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'find-a-lab';

// Responsive reverse columns
$breakpoints: (
  tablet: $tablet,
  desktop: $desktop,
  widescreen: $widescreen,
  fullhd: $fullhd,
);

app-stdcheck-layout,
app-healthlabs-layout,
app-treatmyuti-layout,
app-starfish-layout {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;

  .main-content {
    flex-grow: 1;
  }
}

.columns {
  @each $name, $value in $breakpoints {
    &.is-reverse-#{$name} {
      @include from($value) {
        flex-direction: row-reverse;
      }
    }
  }
}

.position-relative {
  position: relative;
}

.has-text-weight-ultrabold {
  font-weight: 800;
}

.terms-background {
  background-color: $light-apricot;
}

.hint {
  font-size: 0.75rem;
  margin-top: 0.5em;
}

app-treatmyuti-layout {
  .hint {
    color: $pearly-purple;
    font-weight: bold;
  }
}

app-starfish-layout {
  .hint {
    color: $pastel-red;
    font-weight: bold;

    &.emphasized {
      font-size: 1rem;
    }
  }
}

app-stdcheck-layout {
  .hint {
    color: $primary;
    font-weight: bold;
  }
}

app-healthlabs-layout {
  .hint {
    color: $sea-green;
    font-weight: bold;
  }
}

#shopper_approved {
  #sa_header {
    display: none !important;
  }

  div#sa_outer {
    max-width: none !important;
  }

  div#sa_ratings {
    padding: 0 24px !important;
  }

  #sa_emailbox {
    display: block;
  }

  #sa_optin input[type='text'] {
    width: 100% !important;
    margin-right: 0;
    float: none;
  }

  #sa_footer_img {
    margin-top: 1rem;
  }
}
